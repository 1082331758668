<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents">
        <h2 class="h_title">
          <router-link :to="{ path: '/question_check_list' }">Q&A 답변 현황</router-link>
        </h2>
        <div class="btn_box" style="display: flex; justify-content: space-between">
          <div>
            <a v-bind:class="{ 'btn bg4': confirm_o, 'btn bg': !confirm_o }" @click="toggle_confirm('o')">검수한
              답변</a>&nbsp;
            <a v-bind:class="{ 'btn bg4': confirm_x, 'btn bg': !confirm_x }" @click="toggle_confirm('x')">검수하지 않은
              답변</a>&nbsp;
            <a v-bind:class="{ 'btn bg': !is_error, 'btn bg4': is_error }" @click="toggle_confirm2()">오류 POI</a>
          </div>
          <div>
            <a v-if="mno == 1 || mno == 2 || (mno >= 528 && mno <= 531)" class="btn bg2" @click="excel_download()">엑셀 다운로드</a>
          </div>
        </div>
        <div class="search_wrapper">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <tr>
                <th scope="col">번호</th>
                <th scope="col">POI 번호</th>
                <th scope="col">이름</th>
                <th scope="col">주소</th>
                <th scope="col">등록자(ID)</th>
                <th scope="col">등록 상태</th>
                <th scope="col">검수자(ID)</th>
                <th scope="col">검수 상태</th>
                <th scope="col">POI 상태</th>
                <th scope="col">오류체크</th>
                <th scope="col">오류사유</th>
                <th scope="col">관리</th>
              </tr>
            </thead>
            <tbody>
              <tr v-if="poi_list.length == 0 && search_now != ''">
                <td colspan="12">
                  <span>{{ search_now }}로 검색된 결과가 없습니다</span>
                </td>
              </tr>
              <tr v-else-if="poi_list.length == 0">
                <td colspan="12">
                  <span>로딩중입니다...</span>
                </td>
              </tr>
              <tr v-for="(poi, index) in poi_list" :key="poi.no">
                <td>{{ poi_numbering(index) }}</td>
                <td>{{ poi.poi_no }}</td>
                <td class="txL">{{ poi.poi_name }}</td>
                <td class="txL">{{ poi.poi_address }}</td>
                <td>{{ poi.register }} ({{ poi.register_id }})</td>
                <td>
                  <span v-if="poi.a_state == 1" class="co_state">등록중</span>
                  <span v-if="poi.a_state == 2" class="co_state">등록완료</span>
                  <span v-if="poi.a_state == 3" class="co_state4">검수요청</span>
                  <span v-if="poi.a_state == 4" class="co_state1">검수승인</span>
                </td>
                <td>{{ poi.confirmer }} ({{ poi.confirmer_id }})</td>
                <td>
                  <span v-if="poi.a_confirm == 0" class="co_state">검수대기</span>
                  <span v-if="poi.a_confirm == 1" class="co_state1">승인</span>
                  <span v-if="poi.a_confirm == -1" class="co_state3">반려</span>
                </td>
                <td>
                  <span v-if="poi.ac_is_reject == 1 || poi.qc_is_reject == 1" class="co_state3">오류</span>
                  <span v-else class="co_state1">정상</span>
                </td>
                <td><a @click="reject_toggle(poi.no)" class="btn bg">오류체크</a></td>
                <td>{{ poi.ac_reject_reason }}</td>
                <td>
                  <router-link :to="{ path: '/answer_view/' + poi.no }" class="btn size1">답변보기</router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="page_box mgT10">
          page :
          <input type="text" class="page" v-model="page" @input="paging($event, true)" />
          / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
        <!-- //table_type -->
      </div>
    </div>
    <QA_Reject :reject_flag="reject_flag" :qna="qna" @close="reject_toggle" ref="rejectPop" />
  </div>
</template>

<script>
// @ is an alias to /src
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import QA_Reject from "../components/QA_Reject.vue";


export default {
  name: "QuestionCheckList",
  data: function () {
    return {
      search: "",
      search_now: "",
      search_keyup: 0,
      search_queue: false,
      page: 1,
      page_total: 1,
      limit: 20,
      poi_num: 1,
      poi_list: [],
      confirm_o: true,
      confirm_x: true,
      is_error: 0,
      mno: 0,

      qna: "a",
      reject_flag: false,
    };
  },
  components: {
    Header, QA_Reject
  },
  methods: {
    toggle_confirm(ox) {
      if (ox == 'o') {
        if (!this.confirm_o || this.confirm_x) {
          this.confirm_o = !this.confirm_o;
          this.list_load();
        }
      }
      else if (ox == 'x') {
        if (!this.confirm_x || this.confirm_o) {
          this.confirm_x = !this.confirm_x;
          this.list_load();
        }
      }
    },
    toggle_confirm2() {
      this.is_error = this.is_error ? false : true;
      this.list_load();
    },
    paging(count, to = false) {
      // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = Number(this.page) + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.list_load();
    },
    poi_numbering(num) {
      // POI 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - (this.page - 1) * this.limit - num;
      } else {
        return this.poi_num - (this.page_total - 1) * this.limit - num;
      }
    },
    list_load() {
      let confirm = "";
      if (this.confirm_o == !this.confirm_x) {
        if (this.confirm_o) confirm = 1;
        if (this.confirm_x) confirm = 2;
      }

      this.poi_list = [];
      let search = "";
      if (this.search !== "") {
        search = this.search;
      }
      axios({
        url: config.apiurl + "?part=qna&mode=qna_a_check_list2",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: search,
          page: this.page,
          limit: this.limit,
          confirm: confirm,
          is_error: this.is_error
        },
      })
        .then((response) => {
          let d = response.data
          console.log(d)
          this.poi_list = d.list;
          this.poi_num = d.poi_num;
          this.page_total = d.page_total;
          this.mno = d.mno;
          common.set_page_info(this, this.$route.name, this.page, search);
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log(error);
        });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.list_load();
      }, 250);
    },
    reject_toggle(qna_no) {
      // 반려사유 토글
      this.reject_flag = !this.reject_flag;
      if (this.reject_flag) {
        this.$refs.rejectPop.load_reject(qna_no);
      }
    },
    excel_download() {
      // 엑셀
      console.log("excel download call");
      let confirm_m = "전체 리스트를 다운로드 하시겠습니까?";

      if (this.confirm_o == !this.confirm_x) {
        if (this.confirm_o) {
          if (this.is_error) {
            confirm_m = "검수한 답변 중 오류 POI만 다운로드 하시겠습니까?";
          } else {
            confirm_m = "검수한 답변 리스트를 다운로드 하시겠습니까?";
          }
        } else {
          if (this.is_error) {
            confirm_m = "검수하지 않은 답변 중 오류 POI만 다운로드 하시겠습니까?";
          } else {
            confirm_m = "검수하지 않은 답변 리스트를 다운로드 하시겠습니까?";
          }
        }
      } else {
        if (this.is_error) {
          confirm_m = "전체 리스트 중 오류POI만 다운로드 하시겠습니까?";
        }
      }

      if (this.search != "") {
        confirm_m += ` [검색어: ${this.search}]`;
      }

      if (confirm(confirm_m)) {
        console.log("download start");
        alert("다운로드가 시작됩니다. 개수가 많을 경우 시간이 조금 소요될 수 있습니다.");

        let confirm = "";
        if (this.confirm_o == !this.confirm_x) {
          if (this.confirm_o) confirm = 1;
          if (this.confirm_x) confirm = 2;
        }

        let search = "";
        if (this.search !== "") {
          search = this.search;
        }
        axios({
          url: config.apiurl + "?part=qna&mode=qna_a_excel",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            search: search,
            confirm: confirm,
            is_error: this.is_error,
          },
        }).then(async (response) => {
          let d = response.data
          // console.log(d);
          console.log(d.list.length)

          await this.make_excel(d.list);

        }).catch(function (error) {
          alert("오류가 발생했습니다...(2)");
          console.log(error);
        });
      }
    },
    make_excel(list) {
      return new Promise((resolv) => {
        // console.log(list)
        console.log("make excel call");
        let csvContent = "data:text/csv;charset=utf-8," + "\ufeff";

        let cols = [["POI번호", "이름", "주소", "등록자(ID)", "등록상태", "검수자(ID)", "검수상태", "POI상태", "질문측 오류사유", "답변측 오류사유"]];
        cols.forEach(function (colArray) {
          let col = colArray.join(",");
          csvContent += col + "\r\n";
        });

        const rows = [];
        for (let i = 0; i < list.length; i++) {
          let row = list[i];
          let r = `${row['register_name']}(${row['register_id']})`;
          let c = `${row['confirmer_name']}(${row['confirmer_id']})`;
          if (row['confirmer_name'] == null) {
            c = "없음";
          }
          let reject = "정상";
          if (row['qc_is_reject'] == 1 || row['ac_is_reject'] == 1) reject = "오류";

          rows.push(
            [
              row['poi_no'],
              `"${row['poi_name']}"`,
              `"${row['poi_address']}"`,
              r,
              row['a_state'],
              c,
              row['a_confirm'],
              reject,
              `"${row['qc_reject_reason']}"`,
              `"${row['ac_reject_reason']}"`
            ]
          );
        }

        rows.forEach(function (rowArray) {
          let row = rowArray.join(",");
          csvContent += row + "\r\n";
        });

        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", "my_data.csv");
        document.body.appendChild(link); // Required for FF

        link.click(); // This will download the data file named "my_data.csv".

        resolv(1);
      })
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 56);
    await common.set_page(this);
    this.list_load();
  },
};
</script>


<style>
.search_wrapper {
  padding: 12px;
}

.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>