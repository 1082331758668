<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/question_confirm_list/' }">Q&A 질문 검수 목록</router-link>
        </h2>

        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">POI 정보</h2>

            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all;">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="list_box">
            <h2 class="h_title">Triple</h2>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="triple.length == 0">
                    <td colspan="3" style="text-align: center">
                      등록된 Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t in triple" :key="t">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td style="word-break: break-all;">{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div class="mgT20">
          <span>검수자의 한마디</span>
          <textarea class="textareabox" v-model="q_reject_reason" :readonly="q_state != 3"></textarea>
          <span style="color: blue">※ 모든 이미지에 대해 질문 검수가 완료되어야 검수완료를 할 수
            있습니다.</span>
        </div>

        <div class="fx-5 mgT20">
          <div v-for="(img, index) in img_list" :key="img" class="list_box">
            <div style="display: flex; justify-content: space-between">
              <h2 class="h_title">이미지{{ index + 1 }} 질문 검수</h2>
              <!-- 검수요청상태인 Q&A만 조작가능 -->
              <div v-if="q_state == 3">
                <a @click="q_submit(index + 1, img.no)" class="btn bg2">승인</a>&nbsp;
                <a @click="q_reject(index + 1, img.no)" class="btn bg3">반려</a>
              </div>
            </div>

            <div>
              <span>[반려사유]</span>
              <textarea :id="'rr' + img.no" class="textareabox" style="height: 100px" :value="img.reject_reason"
                :readonly="q_state != 3"></textarea>
            </div>

            <div class="qna_img_box mgT10">
              <p v-if="img.q_confirm == -1" style="color: red">[반려]</p>
              <p v-if="img.q_confirm == 0" style="color: gray">[대기]</p>
              <p v-if="img.q_confirm == 1" style="color: blue">[승인]</p>
              <img class="mgT10" v-if="img.img_url != ''" :src="'http://' + img_list[index].img_url"
                title="클릭하면 새창으로 원본을 볼 수 있습니다." onclick="window.open(this.src)" style="cursor: pointer" />
            </div>
            <!-- table_type -->
            <div class="table_type2 mgT20">
              <table>
                <tbody>
                  <tr>
                    <td>
                      [Caption1]<textarea v-if="img_list.length > 0" class="textareabox"
                        v-model="img_list[index].caption1" readonly style="height: 100px">
                      </textarea>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      [Caption2]<textarea v-if="img_list.length > 0" class="textareabox"
                        v-model="img_list[index].caption2" readonly style="height: 100px">
                      </textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="mgT20">
                <tbody v-for="i in 4" :key="i">
                  <tr>
                    <th style="word-break: break-all">
                      {{ img_list[index].qna[i - 1].key }}.
                      <input type="text" class="inputbox" v-model="img_list[index].qna[i - 1].q" />
                    </th>
                  </tr>
                  <tr>
                    <td>
                      <span style="font-weight: 600">Triple</span>
                      <p v-for="(t, idx) in img_list[index].qna[i - 1].triple" :key="t" style="word-break: break-all">
                        <template v-if="t == '선택안함'">
                          {{ t }}
                        </template>
                        <template v-else> {{ idx + 1 }}. {{ t }} </template>
                      </p>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
          </div>
        </div>

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <template v-if="q_state == 3">
            <template v-if="q_confirm == true">
              <a @click="submit(q_confirm)" class="btn bg2">검수완료</a>
            </template>
            <template v-else>
              <a @click="submit(q_confirm)" class="btn bg0">검수완료</a>
            </template>
            <a @click="reject()" class="btn bg3">반려하기</a>
            <a @click="reject_toggle()" class="btn bg">오류체크</a>
          </template>
          <template v-else>
            <router-link :to="{ path: '/question_confirm_list/' }" class="btn bg">목록으로</router-link>
          </template>
        </div>
        <div v-if="reject_flag" class="mgT10">
          <p>※ 해당 POI에 문제가 있을 경우에만 반려해 주세요.</p>
          <input type="text" class="inputbox" style="width: 500px" id="reject_etc" placeholder="반려 사유" />
          <br />
          <a class="btn bg1 mgT10" @click="reject_submit()">반려 내용 제출하기</a>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <QA_Reject :reject_flag="reject_flag" :qna="qna" @close="reject_toggle" ref="rejectPop" />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import QA_Reject from "../components/QA_Reject.vue";

export default {
  name: "QuestionConfirmView",
  data() {
    return {
      qna_no: this.$route.params.no,
      img_list: [],
      q_reject_reason: "",
      q_confirm: false,
      q_state: 0,
      triple: [],
      reject_flag: false,
      qna: "q",
    };
  },
  components: {
    Header,
    QA_Reject
  },
  methods: {
    q_submit(idx, ino) {
      // 이미지 1개 질문 검수
      if (confirm("이미지" + idx + "의 질문을 검수 처리하시겠습니까?")) {
        // console.log(ino);
        // console.log(this.img_list[idx - 1].qna);

        axios({
          url: config.apiurl + "?part=qna&mode=qna_qc_data_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            qna_no: this.$route.params.no,
            image_no: ino,
            state: 1,
            qna: this.img_list[idx - 1].qna
          },
        }).then((response) => {
          var d = response.data;
          d;
          console.log("==response");
          console.log(d);
          console.log("response==");
          this.all_load();
        }).catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
      }
    },
    q_reject(idx, ino) {
      // 이미지 1개 질문 반려
      let rr = document.getElementById("rr" + ino);
      rr = rr.value;

      if (confirm("이미지" + idx + "의 질문을 반려 처리하시겠습니까?")) {
        axios({
          url: config.apiurl + "?part=qna&mode=qna_qc_data_state",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            qna_no: this.$route.params.no,
            image_no: ino,
            state: -1,
            rr: rr,
          },
        })
          .then((response) => {
            var d = response.data;
            d;
            // console.log(d);
            this.all_load();
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(2)");
            console.log("에러야!");
            console.log(error);
          });
      }
    },
    all_load() {
      // 전체 정보 불러오기 : image, qna
      axios({
        url: config.apiurl + "?part=qna&mode=qna_qc_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          qna_no: this.$route.params.no,
        },
      })
        .then((response) => {
          var d = response.data;
          console.log(d);
          this.img_list = d.image_list;
          this.q_reject_reason = d.q_reject_reason;
          this.q_confirm = d.q_confirm;
          this.q_state = d.q_state;
          this.triple = d.triple;
          this.poi_info = d.poi_info;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(3)");
          console.log("에러야!");
          console.log(error);
        });
    },
    submit(q_confirm) {
      if (!q_confirm) {
        alert(
          "이미지를 전부 승인한 후 검수완료를 할 수 있습니다. 하나라도 승인이 되지 않았다면 반려해 주세요."
        );
      } else {
        if (confirm("검수 완료하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_qc_state",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              state: 1,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("검수완료 되었습니다.");
              this.$router.push({ name: "QuestionConfirmList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(4)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    reject() {
      console.log(this.q_reject_reason);
      if (this.q_reject_reason == "") {
        alert("반려사유를 작성해 주세요.");
      } else {
        if (confirm("반려하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_qc_state",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              state: -1,
              reject_reason: this.q_reject_reason,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("반려 되었습니다.");
              this.$router.push({ name: "QuestionConfirmList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(5)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    reject_toggle() {
      // 반려사유 토글
      this.reject_flag = !this.reject_flag;
      if (this.reject_flag) {
        this.$refs.rejectPop.load_reject(this.qna_no);
      }
    },
    reject_submit() {
      let rc = document.getElementById("reject_etc").value;
      if (rc == "") {
        alert("반려 사유를 작성해 주세요.");
      } else {
        if (
          confirm("반려 내용을 제출하시겠습니까? 이 작업은 돌이킬 수 없습니다.")
        ) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_reject_submit",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              rc: rc,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("POI가 반려 되었습니다.");
              this.$router.push({
                name: "QuestionConfirmList",
              });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(6)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 37);
    this.all_load();
  },
};
</script>