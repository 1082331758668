<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          Q&A 질문 검수 목록
        </h2>

        <div class="table_type2" style="width:300px;">
          <table>
            <colgroup>
              <col />
            </colgroup>
            <thead>
              <tr>
                <th style="text-align:center" scope="col">오늘 검수한 질문</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style="text-align:center">{{ today_confirm }}개</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="btn_box mgT10">
          <a v-bind:class="{ 'btn bg4': page_option.confirm_o, 'btn bg': !page_option.confirm_o }"
            @click="toggle_edit('o')">검수한 POI</a>&nbsp;
          <a v-bind:class="{ 'btn bg4': page_option.confirm_x, 'btn bg': !page_option.confirm_x }"
            @click="toggle_edit('x')">검수하지 않은 POI</a>&nbsp;
        </div>

        <div class="search_wrapper mgT10">
          <input type="text" class="inputbox" @input="doSearch" placeholder="검색어를 입력하세요." />
        </div>

        <!-- table_type -->
        <div class="table_type1 mgT10">
          <table>
            <colgroup>
              <!-- <col width="4%" /> -->
              <col width="6%" />
              <col width="6%" />
              <col />
              <col />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
              <col width="8%" />
            </colgroup>
            <thead>
              <!-- <th scope="col">
                <a @click="select_all()" class="btn bg">
                  <span v-if="select_flag == false">전체선택</span>
                  <span v-else>전체해제</span>
                </a>
              </th> -->
              <th scope="col">번호</th>
              <th scope="col">POI번호</th>
              <th scope="col">이름</th>
              <th scope="col">주소</th>
              <th scope="col">질문등록자(ID)</th>
              <th scope="col">작업상태</th>
              <th scope="col">검수상태</th>
              <th scope="col">POI상태</th>
              <th scope="col">관리</th>
            </thead>
            <tbody>
              <tr v-if="qna_list.length == 0">
                <td colspan="8">
                  <span>할당된 캡션&질문 검수 작업이 없습니다.</span>
                </td>
              </tr>

              <tr v-for="(qna, index) in qna_list" :key="qna">
                <!-- <td>
                  <input type="checkbox" name="confirm" v-bind:value="[qna.no]">
                </td> -->
                <td>{{ qna_numbering(index) }}</td>
                <td>{{ qna.poi_no }}</td>
                <td class="txL">{{ qna.poi_name }}</td>
                <td class="txL">{{ qna.poi_address }}</td>
                <td>{{ qna.username }}({{qna.userid}})</td>
                <td>
                  <span v-if="qna.q_state == 1" class="co_state">등록대기</span>
                  <span v-else-if="qna.q_state == 2" class="co_state2">등록완료</span>
                  <span v-else-if="qna.q_state == 3" class="co_state4">검수요청</span>
                  <span v-else-if="qna.q_state == 4" class="co_state1">검수완료</span>
                  <span v-else-if="qna.q_state == -1" class="co_state3">반려 또는 취소</span>
                </td>
                <td>
                  <span v-if="qna.q_confirm == 0" class="co_state">검수대기</span>
                  <span v-else-if="qna.q_confirm == 1" class="co_state1">검수완료</span>
                  <span v-else-if="qna.q_confirm == -1" class="co_state3">반려</span>
                </td>
                <td>
                  <span v-if="qna.reject == 1" class="co_state3">오류</span>
                  <span v-if="qna.reject == 0" class="co_state1">정상</span>
                </td>
                <td>
                  <router-link v-if="qna.q_state == 3" :to="{ path: '/question_confirm_view/' + qna.no }"
                    class="btn size1">검수
                  </router-link>
                  <router-link v-else :to="{ path: '/question_confirm_view/' + qna.no }" class="btn size1 bg2">확인
                  </router-link>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="page_box mgT10">
          page : <input type="text" class="page" v-model="page" @input="paging($event, true)"> / {{ page_total }}
          &nbsp;
          <a @click="paging(-1)" class="btn size1">이전</a>
          &nbsp;
          <a @click="paging(1)" class="btn size1">다음</a>
        </div>
      </div>
      <!-- //contents -->
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: 'QuestionConfirmList',
  data() {
    return {
      search: "",
      search_now: "",
      search_queue: false,

      qna_list: "",
      page: 1,
      page_total: 1,
      limit: 10,
      poi_num: 1,
      select_flag: false,
      page_option: {
        confirm_o: true,
        confirm_x: true,
        editup: false,
        type_filter: [
          { type: "관광지", value: false },
          { type: "문화시설", value: false },
          { type: "쇼핑", value: false },
          { type: "레포츠", value: false },
          { type: "편의오락", value: false },
          { type: "음식점", value: false },
          { type: "숙박", value: false },
        ],
      },
      today_confirm: 0,
    };
  },
  components: {
    Header
  },
  methods: {
    select_all() {
      var checkboxs = document.getElementsByName('confirm');
      if (!this.select_flag) {
        checkboxs.forEach(checkbox => {
          console.log(checkbox.checked);
          checkbox.checked = true;
        });
        this.select_flag = true;
      } else {
        checkboxs.forEach(checkbox => {
          console.log(checkbox.checked);
          checkbox.checked = false;
        });
        this.select_flag = false;
      }
    },
    toggle_edit(ox) {
      if (ox == 'o') {
        if (!this.page_option.confirm_o || this.page_option.confirm_x) {
          this.page_option.confirm_o = !this.page_option.confirm_o;
          this.qna_load();
        }
      }
      else if (ox == 'x') {
        if (!this.page_option.confirm_x || this.page_option.confirm_o) {
          this.page_option.confirm_x = !this.page_option.confirm_x;
          this.qna_load();
        }
      }
      else if (ox == 'up') {
        this.page_option.editup = !this.page_option.editup;
        this.qna_load();
      }
    },
    paging(count, to = false) { // 리스트 페이징
      var c = 1;
      if (to) {
        c = Number(count.target.value);
      } else {
        c = this.page + count;
      }

      if (c >= 1 && c <= this.page_total) {
        this.page = c;
      }
      this.qna_load();
    },
    qna_numbering(num) { // qna 목록 번호 세팅
      if (this.page < 1) {
        return this.poi_num - num;
      } else if (this.page >= 1 && this.page <= this.page_total) {
        return this.poi_num - ((this.page - 1) * this.limit) - num;
      } else {
        return this.poi_num - ((this.page_total - 1) * this.limit) - num;
      }
    },
    qna_load() { // qna 정보 불러오기
      let confirm = "";
      if (this.page_option.confirm_o == !this.page_option.confirm_x) {
        if (this.page_option.confirm_o) confirm = 1;
        if (this.page_option.confirm_x) confirm = 2;
      }

      let search = "";
      if (this.search != "") {
        search = this.search;
      }

      axios({
        url: config.apiurl + "?part=qna&mode=qna_qc_list",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          search: search,
          page: this.page,
          limit: this.limit,
          confirm: confirm,
        },
      }).then((response) => {
        let d = response.data;
        console.log(d)
        this.qna_list = d.list;
        this.poi_num = d.poi_num;
        this.page_total = d.page_total;
        this.today_confirm = d.today_confirm;
        common.set_page_info(this, this.$route.name, this.page, this.search, this.page_option);
      }).catch(function (error) {
        alert("오류가 발생했습니다...(1)");
        console.log("에러야!");
        console.log(error);
      });
    },
    doSearch: function (e) {
      this.search = e.target.value;
      clearTimeout(this.search_queue);
      this.search_queue = setTimeout(() => {
        this.search_now = this.search;
        this.page = 1;
        this.qna_load();
      }, 250);
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, 37);
    await common.set_page(this);
    this.qna_load();
  }
};
</script>

<style>
.page {
  width: 50px;
  border: 0;
  border-bottom: 1px red solid;
  ;
  text-align: center;
  box-sizing: border-box;
}

.page_box * {
  box-sizing: border-box;
}

.page_box {
  line-height: 18px;
}

.page_box a {
  user-select: none;
}
</style>