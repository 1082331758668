<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/answer_list/' }">Q&A 답변 등록 목록</router-link>
        </h2>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <th scope="col">이미지</th>
              <th scope="col">이미지1</th>
              <th scope="col">이미지2</th>
              <th scope="col">이미지3</th>
              <th scope="col">이미지4</th>
              <th scope="col">이미지5</th>
            </thead>
            <tbody>
              <tr>
                <th>상태</th>

                <th v-for="(i, index) in img_list" :key="i">
                  <a v-if="i.state == 1" @click="page(index + 1, true)" class="btn">작성완료</a>
                  <a v-else @click="page(index + 1, true)" class="btn line2">작성중</a>

                  <p v-if="i.a_confirm == 1" style="color: blue">[승인]</p>
                  <p v-if="i.a_confirm == -1" style="color: red">[반려]</p>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a v-if="a_state == 1 && view_flag > 0" @click="reject_toggle()" class="btn bg">오류체크</a>
          <a v-if="a_state == 1 && view_flag > 0" @click="submit_all()" class="btn bg2">등록확정</a>
        </div>
        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">POI정보</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <div class="view_top mgT30">
              <h2 class="h_title">
                <span>Triple</span>
              </h2>
            </div>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="triple.length == 0">
                    <td colspan="3" style="text-align: center">
                      등록된 Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t in triple" :key="t" style="word-break: break-all">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td>{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="list_box">
            <h2 class="h_title">이미지{{ img_page }} 답변 등록</h2>
            <div class="qna_img_box">
              <img v-if="img_list.length > 0" :src="'http://' + img_list[img_page - 1].img_url" alt="" />
              <div class="btn_box">
                <a @click="page(-1)" class="prev">
                  <i class="fa-solid fa-circle-chevron-left"></i>
                </a>
                <a @click="page(1)" class="next">
                  <i class="fa-solid fa-circle-chevron-right"></i>
                </a>
              </div>
            </div>
            <!-- table_type -->

            <div class="mgT20" v-if="img_list.length > 0">
              <div class="confirmer_say" v-if="a_confirm != 0">
                <h2 class="h_title"><span>검수자의 한마디</span></h2>
                <textarea class="textareabox" v-model="a_reject_reason" style="height: 70px" readonly></textarea>
              </div>
              <div class="reaject_reason mgT20" v-if="img_list[img_page - 1].a_confirm == -1">
                <h2 class="h_title">
                  <span style="color: red">반려사유</span>
                </h2>
                <textarea class="textareabox" v-model="img_list[img_page - 1].a_reject_reason" style="height: 70px"
                  readonly></textarea>
              </div>
            </div>

            <div class="view_top mgT30" v-if="img_list.length > 0">
              <h2 class="h_title">
                <span>캡션&질문 및 답변</span>
                <span v-if="img_list[img_page - 1].a_confirm == 1" style="color: blue">[승인]</span>
                <span v-if="img_list[img_page - 1].a_confirm == -1" style="color: red">[반려]</span>
              </h2>
            </div>
            <div class="table_type2">
              <table>
                <colgroup>
                  <col width="8%" />
                  <col />
                  <col width="12%" />
                </colgroup>
                <tbody v-if="img_list.length > 0">
                  <tr>
                    <th>캡션1</th>
                    <td colspan="2">
                      <input class="inputbox" id="caption1" v-model="img_list[img_page - 1].caption1" readonly />
                    </td>
                  </tr>
                  <tr>
                    <th>캡션2</th>
                    <td colspan="2">
                      <textarea class="textareabox" id="caption2" v-model="img_list[img_page - 1].caption2"
                        readonly></textarea>
                    </td>
                  </tr>
                  <template v-for="(qna, index) in img_list[img_page - 1].qna" :key="qna">
                    <tr>
                      <th rowspan="2">{{ qna.key }}</th>
                      <td>
                        <ul>
                          <li>
                            <input class="inputbox" v-model="qna.q" />
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="(qc, qcid) in q_category" :key="qc">
                            <input type="checkbox" :value="qc.no" :name="'qc' + index" :id="'qc' + index + qcid"
                              :checked="check_qc(qc.no, qna.cate)" @change="select_check(index)" disabled />
                            <label :for="'qc' + index + qcid" style="user-select: none">{{ qc.desc }}</label><br />
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <td colspan="2" style="position: relative">
                      <ul>
                        <span style="font-weight: 600">Triple</span>
                        <li v-for="t in qna.triple" :key="t" style="word-break:break-all;">
                          {{ t }}
                        </li>
                      </ul>
                    </td>
                    <tr>
                      <th>{{ qna.akey }}</th>
                      <td colspan="2">
                        <input class="inputbox" v-model="qna.a" :readonly="img_list[img_page - 1].a_confirm == 1" />
                      </td>
                    </tr>
                  </template>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->

            <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
              <div v-if="img_list.length > 0 && view_flag > 0">
                <template v-if="img_list[img_page - 1].a_confirm != 1">
                  <template v-if="a_state == 1">
                    <a @click="submit()" class="btn bg2">답변 등록</a>
                  </template>
                  <template v-else>
                    <router-link :to="{ path: '/answer_list/' }" class="btn bg1">확인</router-link>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>
    <QA_Reject :reject_flag="reject_flag" :qna="qna" @close="reject_toggle" ref="rejectPop" />
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";
import QA_Reject from "../components/QA_Reject.vue";

export default {
  name: "AnswerView",
  data() {
    return {
      qna_no: this.$route.params.no,
      triple: [],
      poi_info: "",
      img_list: [],
      img_page: 1,
      current_image: "",
      is_submit: false,
      q_category: [],
      a_state: 0,
      a_reject_reason: "",
      a_confirm: 0,

      reject_flag: false,
      qna: "aa",
      can_request: 0,
    };
  },
  components: {
    Header,
    QA_Reject
  },
  methods: {
    all_load() {
      // 전체 정보 불러오기 : poi, image, qna
      axios({
        url: config.apiurl + "?part=qna&mode=qna_a_view",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        method: "POST",
        data: {
          token: this.$store.state.token,
          qna_no: this.$route.params.no,
        },
      })
        .then((response) => {
          let d = response.data;
          console.log(d);
          this.a_state = d.a_state;
          this.triple = d.triple;
          this.poi_info = d.poi_info;
          this.img_list = d.image_list;
          this.q_category = d.q_category;
          this.current_image = d.image_list[0];
          this.a_reject_reason = d.a_reject_reason;
          this.a_confirm = d.a_confirm;
          this.can_request = d.can_request;
          this.view_flag = d.view_flag;
        })
        .catch(function (error) {
          alert("오류가 발생했습니다...(1)");
          console.log("에러야!");
          console.log(error);
        });
    },
    page(count, to = false) {
      // 이미지 이동, to를 주면 바로 감
      if (to) {
        this.img_page = count;
      } else {
        var c = this.img_page + count;
        if (c <= 1) {
          c = 1;
        } else if (c >= 5) {
          c = 5;
        }

        this.img_page = c;
      }
    },
    submit() {
      // 답변 등록
      let qna = this.img_list[this.img_page - 1].qna;
      let img_no = this.img_list[this.img_page - 1].no;
      let a_check = true;

      qna.forEach((element) => {
        if (element.a == "") {
          a_check = false;
        }
      });

      if (!a_check) {
        alert("답변을 전부 작성해 주세요.");
      } else {
        if (confirm("답변을 등록하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_a_upload",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              img_no: img_no,
              qna: qna,
            },
          })
            .then((response) => {
              let d = response.data;
              console.log(d);
              this.all_load();
              if (this.img_page == 5 && d.can_request == 1) {
                this.can_request = d.can_request;
                this.submit_all();
              } else {
                this.page(1);
              }
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(2)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    submit_all() {
      // 등록확정
      if (this.can_request != 1) {
        alert("모든 이미지에 답변을 작성해야 등록을 확정할 수 있습니다.");
      } else {
        if (confirm("등록을 확정하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_a_state",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              state_qna: 2,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("등록확정 되었습니다.");
              this.$router.push({ name: "AnswerList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(3)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    select_check(qna_cate, index) {
      // 질문카테고리 체크시 추가
      var q = document.getElementsByName("qc" + index);
      var clist = [];
      q.forEach((e) => {
        if (e.checked) {
          clist.push(e.value);
        }
      });
      var cate = clist.join(",");

      var qna = this.img_list[this.img_page - 1].qna;
      qna[index].cate = cate;
    },
    check_qc(qc, qna_cate) {
      // 질문카테고리 체크
      if (qna_cate.indexOf(qc) > -1) {
        return true;
      } else {
        return false;
      }
    },
    reject_toggle() { // 반려사유 토글
      if (this.can_request == 1) {
        this.reject_flag = !this.reject_flag;
        if (this.reject_flag) {
          this.$refs.rejectPop.load_reject(this.qna_no);
        }
      }else{
        alert("답변을 모두 등록한 후에 사용해 주세요.");
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, [33, 56]);
    this.all_load();
  },
};
</script>