<template>
  <div id="container">
    <!-- header -->
    <Header />
    <!-- /header -->
    <div id="contents_box">
      <!-- contents -->
      <div id="contents" class="wrap_box">
        <h2 class="h_title">
          <router-link :to="{ path: '/question_list/' }">Q&A 질문 등록 목록</router-link>
        </h2>
        <!-- table_type -->
        <div class="table_type1">
          <table>
            <colgroup>
              <col />
              <col />
              <col />
              <col />
              <col />
              <col />
            </colgroup>
            <thead>
              <th scope="col">이미지</th>
              <th scope="col">이미지1</th>
              <th scope="col">이미지2</th>
              <th scope="col">이미지3</th>
              <th scope="col">이미지4</th>
              <th scope="col">이미지5</th>
            </thead>
            <tbody>
              <tr>
                <th>상태</th>

                <th v-for="(i, index) in img_list" :key="i">
                  <a v-if="i.state == 1" @click="page(index + 1, true)" class="btn">작성완료</a>
                  <a v-else @click="page(index + 1, true)" class="btn line2">작성중</a>

                  <p v-if="i.q_confirm == 1" style="color: blue">[승인]</p>
                  <p v-if="i.q_confirm == -1" style="color: red">[반려]</p>
                </th>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- //table_type -->

        <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
          <a v-if="q_state == 1 && view_flag > 0" @click="submit_all()" class="btn bg2">등록확정</a>
        </div>
        <div class="fx-5">
          <div class="list_box">
            <h2 class="h_title">POI정보</h2>

            <!-- table_type -->
            <div class="table_type3">
              <table>
                <colgroup>
                  <col width="20%" />
                  <col />
                </colgroup>
                <tbody>
                  <tr v-for="info in poi_info" :key="info">
                    <th scope="row">{{ info[0] }}</th>
                    <td style="word-break: break-all;">{{ info[1] }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!-- //table_type -->
            <div class="view_top mgT30">
              <h2 class="h_title">
                <span>Triple</span>
              </h2>
            </div>
            <div class="table_type3">
              <table>
                <colgroup>
                  <col />
                  <col />
                  <col />
                </colgroup>
                <tbody>
                  <tr>
                    <th>A</th>
                    <th>R</th>
                    <th>B</th>
                  </tr>
                  <tr v-if="triple.length == 0">
                    <td colspan="3" style="text-align: center">
                      등록된 Triple이 없습니다.
                    </td>
                  </tr>
                  <tr v-for="t in triple" :key="t" style="word-break: break-all;">
                    <td>{{ t.a }}</td>
                    <td>{{ t.r }}</td>
                    <td>{{ t.b }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="list_box">
            <h2 class="h_title">이미지{{ img_page }} 캡션&질문 등록</h2>
            <div class="qna_img_box">
              <img v-if="img_list.length > 0" :src="'http://' + img_list[img_page - 1].img_url" alt="" />
              <div class="btn_box">
                <a @click="page(-1)" class="prev">
                  <i class="fa-solid fa-circle-chevron-left"></i>
                </a>
                <a @click="page(1)" class="next">
                  <i class="fa-solid fa-circle-chevron-right"></i>
                </a>
              </div>
            </div>
            <!-- table_type -->

            <div class="mgT20" v-if="img_list.length > 0">
              <div class="confirmer_say" v-if="q_confirm != 0">
                <h2 class="h_title"><span>검수자의 한마디</span></h2>
                <textarea class="textareabox" v-model="q_reject_reason" style="height: 70px" readonly></textarea>
              </div>
              <div class="reaject_reason mgT20" v-if="img_list[img_page - 1].q_confirm == -1">
                <h2 class="h_title">
                  <span style="color: red">반려사유</span>
                </h2>
                <textarea class="textareabox" v-model="img_list[img_page - 1].q_reject_reason" style="height: 70px"
                  readonly></textarea>
              </div>
            </div>

            <div class="view_top mgT30" v-if="img_list.length > 0">
              <h2 class="h_title">
                <span>캡션 및 질문</span>
                <span v-if="img_list[img_page - 1].q_confirm == 1" style="color: blue">[승인]</span>
                <span v-if="img_list[img_page - 1].q_confirm == -1" style="color: red">[반려]</span>
              </h2>
            </div>
            <div class="table_type2">
              <table>
                <colgroup>
                  <col width="8%" />
                  <col />
                  <col width="13%" />
                </colgroup>
                <tbody v-if="img_list.length > 0">

                  <tr>
                    <th>캡션1</th>
                    <td colspan="2">
                      <input class="inputbox" id="caption1" v-model="img_list[img_page - 1].caption1" readonly>
                    </td>
                  </tr>
                  <tr>
                    <th>캡션2</th>
                    <td colspan="2">
                      <textarea class="textareabox" id="caption2" v-model="img_list[img_page - 1].caption2"
                        :readonly="img_list[img_page - 1].q_confirm == 1"></textarea>
                    </td>
                  </tr>

                  <template v-for="(qna, index) in img_list[img_page - 1].qna" :key="qna">
                    <tr>
                      <th rowspan="2">{{ qna.key }}</th>
                      <td>
                        <ul>
                          <li>
                            <select v-if="img_list[img_page - 1].q_confirm != 1" class="inputbox"
                              @change="set_q($event, qna.key)">
                              <option :value="['', , 4, , index]" selected>직접입력</option>
                              <option :value="[q.desc, , q.category, , index]" v-for="q in pre_q" :key="q">
                                {{ q.cate_desc2 }} : {{ q.desc }}
                              </option>
                            </select>
                          </li>
                          <li>
                            <input class="inputbox" v-model="qna.q" :readonly="img_list[img_page - 1].q_confirm == 1" />
                            <!-- <input class="inputbox" v-model="qna.q" readonly /> -->
                          </li>
                        </ul>
                      </td>
                      <td>
                        <ul>
                          <li v-for="(qc, qcid) in q_category" :key="qc">
                            <input type="checkbox" :value="qc.no" :name="'qc' + index" :id="'qc' + index + qcid"
                              :checked="check_qc(qc.no, qna.cate)" @change="select_check(index)" :disabled="
                                // img_list[img_page - 1].q_confirm == 1 || q_state > 1
                                1
                              " />
                            <label :for="'qc' + index + qcid" style="user-select: none;" >{{ qc.desc }}</label><br />
                          </li>
                        </ul>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" style="position: relative;">
                        <ul>
                          <!-- 트리플 선택안함 -->
                          <!-- <li>
                            <input type="radio" value="0" :id="'triple_none' + index" :checked="qna.triple == '0'"
                              @change="select_triple($event, index, true)"
                              :disabled="img_list[img_page - 1].q_confirm == 1 || q_state > 1">
                            <label :for="'triple_none' + index" style="user-select: none;">선택안함</label><br />
                          </li> -->

                          <li v-for="(t, tid) in triple" :key="t">
                            <input type="radio" :value="t.no" :name="'triple' + index" :id="'triple' + index + tid"
                              :checked="check_triple(t.no, qna.triple)" @change="select_triple($event, index)"
                              :disabled="img_list[img_page - 1].q_confirm == 1 || q_state > 1" />
                            <label :for="'triple' + index + tid" style="user-select: none; word-break: break-all;">{{ t.desc }}</label><br />
                          </li>

                        </ul>

                        <!-- <p
                        style="position: absolute; left: 0; top: 10px; transform: rotate(-35deg); color: rgba(0, 0, 0, 0.2); font-size: 26px; font-weight: bold;">
                        트리플</p> -->
                      </td>
                    </tr>
                  </template>

                </tbody>
              </table>
            </div>
            <!-- //table_type -->

            <div class="list_bot mgT20" style="justify-content: end; gap: 5px">
              <div v-if="img_list.length > 0 && view_flag > 0">
                <template v-if="img_list[img_page - 1].q_confirm != 1">
                  <template v-if="q_state == 1">
                    <template v-if="checked == 0">
                      <a @click="submit()" class="btn bg2">캡션&질문 등록</a>
                    </template>
                    <template v-else>
                      <span>등록중입니다...</span>
                    </template>
                  </template>
                  <template v-else>
                    <router-link :to="{ path: '/question_list/' }" class="btn bg1">확인</router-link>
                  </template>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- //contents -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../components/Header.vue";
import common from "../common/common.js";
import config from "../common/config.js";

export default {
  name: "QuestionView",
  data() {
    return {
      triple: [],
      poi_info: "",
      img_list: [],
      img_page: 1,
      current_image: "",
      is_submit: false,
      q_category: [],
      q_state: 0,
      q_reject_reason: "",
      q_confirm: 0,
      pre_q: [], // 미리 준비된 질문, DB에서 가져옴
      dong_eup_myeon: ["동", "읍", "면"],
      checked: 0,
    };
  },
  components: {
    Header,
  },
  methods: {
    set_caption1(address) {
      console.log("set_caption1 call");
      let address_split = address.split(" ");
      let dong = "";
      let dong2 = "";
      let last_char2 = "";
      let result = "";
      if (address_split.length >= 3) {
        dong = address_split[2];
        dong2 = address_split[3];
        last_char2 = dong2.charAt(dong2.length - 1)

        if (this.dong_eup_myeon.indexOf(last_char2) >= 0) { // 4번째 항목이 읍면동이면
          result = dong2;
        } else {
          result = dong;
        }
      }

      this.img_list[this.img_page - 1].caption1 = this.img_list[this.img_page - 1].caption1 + "(" + result + ")";
    },
    async address_check() { // 캡션1 괄호안에 구 주소 3번째부분 처리
      let address1 = ""; // 주소
      let address2 = ""; // 도로명주소
      let address3 = ""; // 지번주소
      address1, address2, address3;

      this.poi_info.forEach(poi_data => {
        if (poi_data[0] == '주소') { address1 = poi_data[1]; }
        else if (poi_data[0] == '주소(도로명)') { address2 = poi_data[1]; }
        else if (poi_data[0] == '주소(지번주소)') { address3 = poi_data[1]; }
      });

      if (address3 != "") { // 지번주소가 있는 경우
        this.set_caption1(address3);
      } else if (address2) { // 지번주소가 없으면 도로명주소로 지번주소 획득 후 삽입
        let address_info = await this.address_search(address2);
        if (address_info.address) { // 지번주소 획득 성공
          this.set_caption1(address_info.address);
        } else { // 도로명주소로 지번획득 실패시 빈칸삽입
          this.set_caption1();
        }
      } else { // 도로명주소도 없으면 주소로 지번주소 획득 후 삽입
        let address_info = await this.address_search(address1);
        if (address_info.address) { // 지번주소 획득 성공
          this.set_caption1(address_info.address);
        } else { // 도로명주소로 지번획득 실패시 빈칸삽입
          this.set_caption1();
        }
      }
    },
    async address_search(address) {
      // console.log(address);
      return new Promise((resolv, reject) => {
        let result = {};
        if (address == "") {
          result["coord"] = [0, 0];
          resolv(result);
        } else {
          var geocoder = new kakao.maps.services.Geocoder();

          geocoder.addressSearch(address, (result, status) => {
            if (result.length == 0) {
              result["coord"] = [0, 0];
              resolv(result);
            } else {
              if (
                Object.prototype.hasOwnProperty.call(result[0], "address") &&
                result[0].address !== null
              ) {
                result["address"] = result[0].address.address_name;
              }
              if (
                Object.prototype.hasOwnProperty.call(
                  result[0],
                  "road_address"
                ) &&
                result[0].road_address !== null
              ) {
                result["road_address"] = result[0].road_address.address_name;
              }

              if (status === kakao.maps.services.Status.OK) {
                // console.log("해당 좌표 : " + result[0].y + ", " + result[0].x);
                result["coord"] = [result[0].y, result[0].x];
                resolv(result);
              } else {
                result["coord"] = [0, 0];
                reject(result);
                console.log("[좌표 없음]" + address);
              }
            }
          });
        }
      });
    },
    set_pre_q() { // 맨처음에 미리 랜덤으로 질문 세팅해놓기
      let count = 3; count;
      let q = ['질문1', '질문2', '질문3'];

      let copy_pre_q = [];
      let rand_q = [];

      this.pre_q.forEach(pre_q => {
        copy_pre_q.push(pre_q);
      });
      count = copy_pre_q.length - count;

      while (copy_pre_q.length > count) {
        let element = copy_pre_q.splice(Math.random() * copy_pre_q.length, 1)[0]
        rand_q.push(element);
      }

      for (let i = 0; i < rand_q.length; i++) {
        const element = rand_q[i];

        this.img_list[this.img_page - 1].qna.forEach((qna) => {
          if (qna.key == q[i]) {
            qna.q = element['desc'];
          }
        });
        var qna = this.img_list[this.img_page - 1].qna;
        qna[i].cate = element['category'];
      }
    },
    set_q(e, qna_key) {
      // 준비된 질문 선택
      let val = e.target.value;
      let v = val.split(",,")

      this.img_list[this.img_page - 1].qna.forEach((qna) => {
        if (qna.key == qna_key) {
          qna.q = v[0];
        }
      });
      var qna = this.img_list[this.img_page - 1].qna;
      qna[v[2]].cate = v[1];
    },
    async all_load() {
      // 전체 정보 불러오기 : poi, image, qna
      return new Promise((resolv) => {
        axios({
          url: config.apiurl + "?part=qna&mode=qna_q_view",
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
          method: "POST",
          data: {
            token: this.$store.state.token,
            qna_no: this.$route.params.no,
          },
        })
          .then((response) => {
            let d = response.data;
            console.log(d);
            this.q_state = d.q_state;
            this.can_request = d.can_request;
            this.triple = d.triple;
            this.poi_info = d.poi_info;
            this.img_list = d.image_list;
            this.q_category = d.q_category;
            this.current_image = d.image_list[0];
            this.q_reject_reason = d.q_reject_reason;
            this.q_confirm = d.q_confirm;
            this.pre_q = d.pre_q;
            this.view_flag = d.view_flag;

            // 최초에만 진행
            if (d.q_confirm == 0 && this.img_list[this.img_page - 1].state == 0 && this.img_list[this.img_page - 1].flag == 0 && this.img_list[this.img_page - 1].location_check == 0) {
              console.log("최초진행")
              this.set_pre_q();
              this.address_check();
              this.img_list[this.img_page - 1].flag = 1;
            }
            resolv(1);
          })
          .catch(function (error) {
            alert("오류가 발생했습니다...(1)");
            console.log("에러야!");
            console.log(error);
          });
      })


    },
    page(count, to = false) {
      // 이미지 이동, to를 주면 바로 감
      if (to) {
        this.img_page = count;
      } else {
        var c = this.img_page + count;
        if (c <= 1) {
          c = 1;
        } else if (c >= 5) {
          c = 5;
        }

        this.img_page = c;
      }
      if (this.q_confirm == 0 && this.img_list[this.img_page - 1].state == 0 && this.img_list[this.img_page - 1].flag == 0 && this.img_list[this.img_page - 1].location_check == 0) {
        console.log("페이지이동진행")
        this.set_pre_q();
        this.address_check();
        this.img_list[this.img_page - 1].flag = 1;
      }
    },
    submit() {
      // 질문 등록
      let img_no = this.img_list[this.img_page - 1].no;
      let qna = this.img_list[this.img_page - 1].qna;
      let caption1 = this.img_list[this.img_page - 1].caption1;
      let caption2 = this.img_list[this.img_page - 1].caption2;

      let q_check = true; // 질문 내용 체크
      let cate_check = true; // 질문 카테고리 체크
      qna.forEach((element) => {
        if (element.cate == "") {
          cate_check = false;
        }
        if (element.q == "") {
          q_check = false;
        }
      });
      console.log(qna);

      // 트리플 없어도 된다고 바뀜........
      let triple_check = true; // 트리플 체크
      qna.forEach((element) => {
        if (element.triple == "0") {
          triple_check = false;
        }
      });

      if (!q_check) {
        alert("질문을 전부 작성해 주세요.");
      }
      else if (!cate_check) {
        alert("질문마다 질문의 종류를 최소 1개는 선택해야 합니다.");
      }
      else if (!triple_check) {
        alert("질문마다 트리플을 반드시 선택해야 합니다.");
      }
      else {
        if (confirm("캡션과 질문을 등록하시겠습니까?")) {
          this.checked = 1;
          axios({
            url: config.apiurl + "?part=qna&mode=qna_q_upload",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              img_no: img_no,
              qna: qna,
              caption1: caption1,
              caption2: caption2,
            },
          })
            .then(async (response) => {
              this.checked = 0;
              let d = response.data;
              console.log(d)
              await this.all_load();
              if (this.img_page == 5 && d.can_request == 1) {
                this.can_request = d.can_request;
                this.submit_all();
              } else {
                this.page(1);
              }
            })
            .catch(function (error) {
              this.checked = 0;
              alert("오류가 발생했습니다...(2)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    submit_all() {
      // 등록확정
      if (this.can_request != 1) {
        alert("모든 이미지에 질문을 작성해야 등록을 확정할 수 있습니다.");
      } else {
        if (confirm("등록을 확정하시겠습니까?")) {
          axios({
            url: config.apiurl + "?part=qna&mode=qna_q_state",
            headers: {
              "Content-Type": "application/json",
              "Access-Control-Allow-Origin": "*",
            },
            method: "POST",
            data: {
              token: this.$store.state.token,
              qna_no: this.$route.params.no,
              state_qna: 2,
            },
          })
            .then((response) => {
              console.log(response.data);
              alert("등록확정 되었습니다.");
              this.$router.push({ name: "QuestionList" });
            })
            .catch(function (error) {
              alert("오류가 발생했습니다...(3)");
              console.log("에러야!");
              console.log(error);
            });
        }
      }
    },
    select_check(index) {
      // 질문카테고리 체크시 추가
      var q = document.getElementsByName("qc" + index);
      var clist = [];
      q.forEach((e) => {
        if (e.checked) {
          clist.push(e.value);
        }
      });
      var cate = clist.join(",");

      var qna = this.img_list[this.img_page - 1].qna;
      qna[index].cate = cate;
    },
    check_qc(qc, qna_cate) {
      // 질문카테고리 체크
      if (qna_cate.indexOf(qc) > -1) {
        return true;
      } else {
        return false;
      }
    },
    select_triple(event, index, none = false) {
      // 트리플 체크시 추가
      let triple = "0";
      if (!none) { // 선택안함 말고 다른거 체크
        triple = event.target.value;
        // var t = document.getElementsByName("triple" + index);
        // var tlist = [];
        // t.forEach((e) => {
        //   if (e.checked) {
        //     tlist.push(e.value);
        //   }
        // });
        // triple = tlist.join(",");
      }

      // console.log(triple)

      var qna = this.img_list[this.img_page - 1].qna;
      qna[index].triple = triple;
    },
    check_triple(t, triple_list) {
      // 트리플 체크
      if (triple_list.indexOf(t) > -1) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted: async function () {
    await common.check_perm(this.$store.state.token, [32, 55]);
    this.all_load();
    const script = document.createElement("script");
    /* global kakao */
    // script.onload = () => kakao.maps.load(this.initMap);
    script.src =
      "//dapi.kakao.com/v2/maps/sdk.js?autoload=false&appkey=6d213af40222b5124ab4c81049cb37c4&libraries=services";
    document.head.appendChild(script);
  },
};
</script>


<style>
.q_select select {
  width: 80px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.q_select select option {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
</style>